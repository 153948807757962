import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from "@material-ui/core";
import React from "react";
import { Layout } from "../../components/Layout";
import { useStores } from "../../contexts";
import { Loading } from "../../components/Loading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    actionBar: {
      position: "fixed",
      right: 15,
      bottom: 15,
      zIndex: 1000,
    },
    actionBarButton: {
      margin: "0 5px",
    },
  })
);

export const SalesCostPage = (props: any) => {
  const { appStore } = useStores();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const init = async () => {
    const response = await appStore.api.get("year-costs");
    setData(response.data);
  };

  React.useEffect(() => {
    appStore.setMenu("sales.cost");

    init();
  }, [appStore]);

  const onUpdate = async (id: any, cost: any) => {
    const response = await appStore.api.put(`year-costs/${id}`, { id, cost });
  };

  if (loading)
    return (
      <Layout history={props.history}>
        <Loading />
      </Layout>
    );

  return (
    <Layout history={props.history}>
      <h2>Company Running Cost</h2>
      <Grid item xs={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Year</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Cost</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.year}
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      onBlur={(e) => onUpdate(row.id, e.target.value)}
                      value={row.cost}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Layout>
  );
};
