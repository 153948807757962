import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { ListSubheader } from "@material-ui/core";
import {
  AssignmentInd,
  CreditCard,
  DirectionsBoat,
  Euro,
  PeopleAlt,
  TrendingUp,
  LocalAtm,
} from "@material-ui/icons";

import DashboardIcon from "@material-ui/icons/Dashboard";

import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import { useStores } from "../contexts";
import { observer } from "mobx-react";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

interface Props {
  window?: () => Window;
  children?: React.ReactNode;
}

export const Navigation = observer((props: Props) => {
  const { window } = props;
  const theme = useTheme();
  const classes = useStyles();
  const stores = useStores();

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src={Logo} width={200} alt="logo" />
      </div>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Data
          </ListSubheader>
        }
      >
        <ListItem
          button
          component={Link}
          to="/"
          selected={stores.appStore.menu === "dashboard"}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/sales"
          selected={stores.appStore.menu === "sales.list"}
        >
          <ListItemIcon>
            <DirectionsBoat />
          </ListItemIcon>
          <ListItemText primary={"Sales"} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/sales/saxdor"
          selected={stores.appStore.menu === "sales.list.saxdor"}
        >
          <ListItemIcon>
            <DirectionsBoat />
          </ListItemIcon>
          <ListItemText primary={"Saxdor Sales"} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/sales/costs"
          selected={stores.appStore.menu === "sales.costs"}
        >
          <ListItemIcon>
            <LocalAtm />
          </ListItemIcon>
          <ListItemText primary={"Yearly Costs"} />
        </ListItem>
        {/*
        <ListItem
          button
          component={Link}
          to="/sales"
          selected={stores.appStore.menu === "auth.login"}
        >
          <ListItemIcon>
            <PeopleAlt />
          </ListItemIcon>
          <ListItemText primary={"Customers"} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentInd />
          </ListItemIcon>
          <ListItemText primary={"Brokers"} />
        </ListItem>
        */}
      </List>
      <Divider />
      {/* <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Reports
          </ListSubheader>
        }
      >
        <ListItem button>
          <ListItemIcon>
            <Euro />
          </ListItemIcon>
          <ListItemText primary={"Turnover"} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <TrendingUp />
          </ListItemIcon>
          <ListItemText primary={"Profit"} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <CreditCard />
          </ListItemIcon>
          <ListItemText primary={"Commission"} />
        </ListItem>
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={stores.appStore.menuOpen}
          onClose={stores.appStore.setMenuOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
});
