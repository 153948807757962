import { Layout } from "../../components/Layout";

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import { useStores } from "../../contexts";

import Paper from "@material-ui/core/Paper";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";

import { EventTracker } from "@devexpress/dx-react-chart";
import { scaleBand } from "@devexpress/dx-chart-core";
import { ArgumentScale, Stack } from "@devexpress/dx-react-chart";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";

interface IProps {
  history: any;
}

export const Dashboard = (props: IProps) => {
  const { appStore } = useStores();
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState("");

  const [data, setData] = useState({
    summary: {
      totalCommissionAmount: 0,
      brokerCostTotal: 0,
      netPrice: 0,
      totalSoldPrice: 0,
      costTotal: 0,
    },
    monthly: [],
    yearly: [],
  });

  const [start, setStart] = useState(undefined);
  const [end, setEnd] = useState(undefined);

  const init = async () => {
    const params = { start, end } as any;

    if (brand) params["brandKey"] = brand;

    const profit = await appStore.api.get("/report/commission", { params });

    setData(profit.data);
    setLoading(false);
  };

  React.useEffect(() => {
    appStore.setMenu("dashboard");

    init();
  }, [appStore]);

  if (loading) return <div>Loading</div>;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Layout history={props.history}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    title="Total Sales"
                    style={{ textAlign: "center" }}
                  ></CardHeader>
                  <Divider />
                  <CardContent>
                    <div
                      style={{
                        fontSize: 24,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {appStore.currencyFormat(
                        data.summary.totalSoldPrice,
                        "EUR"
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    title="Total Profit"
                    style={{ textAlign: "center" }}
                  ></CardHeader>
                  <Divider />
                  <CardContent>
                    <div
                      style={{
                        fontSize: 24,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {appStore.currencyFormat(data.summary.netPrice, "EUR")}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    title="Total Commission"
                    style={{ textAlign: "center" }}
                  ></CardHeader>
                  <Divider />
                  <CardContent>
                    <div
                      style={{
                        fontSize: 24,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {appStore.currencyFormat(
                        data.summary.totalCommissionAmount,
                        "EUR"
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    title="Total Broker Cost"
                    style={{ textAlign: "center" }}
                  ></CardHeader>
                  <Divider />
                  <CardContent>
                    <div
                      style={{
                        fontSize: 24,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {appStore.currencyFormat(
                        data.summary.brokerCostTotal,
                        "EUR"
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    title="Total Other Costs"
                    style={{ textAlign: "center" }}
                  ></CardHeader>
                  <Divider />
                  <CardContent>
                    <div
                      style={{
                        fontSize: 24,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {appStore.currencyFormat(data.summary.costTotal, "EUR")}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Filter"></CardHeader>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel>Brand</InputLabel>
                      <Select
                        fullWidth
                        placeholder="Brand"
                        onChange={(e: any) => setBrand(e.target.value)}
                        value={brand}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="saxdor">Saxdor</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <KeyboardDatePicker
                      format="yyyy-MM-dd"
                      label="Start Date"
                      value={start}
                      onChange={(e: MaterialUiPickersDate, val: any) => {
                        setStart(val);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <KeyboardDatePicker
                      format="yyyy-MM-dd"
                      label="End Date"
                      value={end}
                      onChange={(e: MaterialUiPickersDate, val: any) => {
                        setEnd(val);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ButtonGroup>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => init()}
                      >
                        Filter
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setStart(undefined);
                          setEnd(undefined);
                          init();
                        }}
                      >
                        Clear
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <CardHeader
                title="Annual"
                style={{ textAlign: "center" }}
              ></CardHeader>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Date</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Total Sales</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Total Purchase Price</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Commission</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Broker Costs</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Other Costs</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Company Running Cost</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Profit</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.yearly.map((row: any) => (
                    <TableRow key={row.date}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.soldPrice, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.purchasePrice, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(
                          row.totalCommissionAmount,
                          "EUR"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.brokerCostTotal, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.costTotal, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {row.yearCost &&
                          appStore.currencyFormat(row.yearCost.cost, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {row.yearCost
                          ? appStore.currencyFormat(
                              row.netPrice - row.yearCost.cost,
                              "EUR"
                            )
                          : appStore.currencyFormat(row.netPrice, "EUR")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <CardHeader
                title="Monthly"
                style={{ textAlign: "center" }}
              ></CardHeader>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Date</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Total Sales</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Total Purchase Price</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Commission</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Broker Costs</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Other Costs</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Profit</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.monthly.map((row: any) => (
                    <TableRow key={row.date}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.soldPrice, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.purchasePrice, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(
                          row.totalCommissionAmount,
                          "EUR"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.brokerCostTotal, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.costTotal, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.netPrice, "EUR")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* <Grid item xs={12}>
            <Paper>
              <Chart data={data.yearly}>
                <ArgumentScale factory={scaleBand} />
                <ArgumentAxis />
                <ValueAxis />

                <BarSeries
                  valueField="totalCommissionAmount"
                  argumentField="date"
                  name="Commission"
                />
                <BarSeries
                  valueField="brokerCostTotal"
                  argumentField="date"
                  name="Broker Cost"
                />
                <BarSeries
                  valueField="netPrice"
                  argumentField="date"
                  name="Net Price"
                />
                <Stack />
                <EventTracker />
                <Tooltip />
              </Chart>
            </Paper>
          </Grid> */}
        </Grid>
      </Layout>
    </MuiPickersUtilsProvider>
  );
};
