import axios from "axios";
import { action, makeAutoObservable, toJS } from "mobx";

interface IUser {
  id?: Number;
  token?: string;
  username?: string;
  email?: string;
}

export enum YachtStatus {
  New,
  PreOwned,
}

export interface Broker {
  company?: string;
  name?: string;
  commissionRate?: number;
  commissionAmount?: number;
  position?: string;
}

export interface Cost {
  type?: string;
  name?: string;
  commissionRate?: number;
  commissionAmount?: number;
}

export class Sale {
  constructor() {
    this.brokers = [];
    this.costs = [];
    this.attachments = [];
    this.date = new Date();
  }

  id?: number;
  status?: YachtStatus;
  hullnumber?: string;
  name?: string;
  brand?: string;
  brandKey?: string;
  model?: string;
  year?: string;
  yacht_length?: string;
  purchasePrice?: number;
  listPrice?: number;
  soldPrice?: number;
  totalCommission?: number;
  totalCommissionAmount?: number;
  date?: Date;

  buyerName?: string;
  buyerEmail?: string;
  buyerPhone?: string;

  sellerName?: string;
  sellerEmail?: string;
  sellerPhone?: string;

  brokerCostTotal?: number;
  costTotal?: number;
  netPrice?: number;

  brokers: Broker[];
  costs: Cost[];
  attachments: any[];
}

export default class AppStore {
  user: IUser = {};
  state = "";

  name: string = "Mini Crm";
  index: number = 1;
  menu = "";
  menuOpen = false;

  error = {
    has_error: false,
    message: "",
  };

  sales = {
    form: {
      brokers: [] as Broker[],
      costs: [] as Cost[],
      attachments: [],
    } as Sale,
    files: [] as (File | undefined)[],
  };

  constructor() {
    makeAutoObservable(this);
  }

  currencyFormat = (amount: number | undefined, symbol: string) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: symbol,
      minimumFractionDigits: 0,
    }).format(amount || 0);
  };

  @action setMenuOpen() {
    this.menuOpen = !this.menuOpen;
  }

  @action setMenu = (name: string) => {
    this.menu = name;
  };

  @action checkUser = async () => {
    const userdata = localStorage.getItem("crm:user");

    if (userdata) {
      this.user = JSON.parse(userdata);
    }
  };

  @action login = async (username: string, password: string) => {
    this.logout();

    try {
      const { data } = await this.api.post("auth/local", {
        identifier: username,
        password: password,
      });

      let userdata = {
        token: data.jwt,
        id: data.user.id,
        email: data.user.email,
        username: data.user.username,
      };

      localStorage.setItem("crm:user", JSON.stringify(userdata));

      this.user = userdata;

      return data;
    } catch (e) {
      console.log(e);
      return "error";
    }
  };

  @action logout = async () => {
    localStorage.removeItem("crm:user");
    this.user = {};
    window.history.pushState({}, "", "/auth/login");
  };

  @action createSale = () => {
    this.sales.form = { brokers: [], costs: [], attachments: [], netPrice: 0 };
    this.sales.files = [undefined, undefined, undefined];
  };

  @action fetchSales = async () => {
    const params = { brandKey: this.state };
    const response = await this.api.get("yachts", { params });
    return response.data;
  };

  @action fetchSalesCount = async () => {
    const params = { brandKey: this.state };
    const response = await this.api.get("yachts/count", { params });
    return response.data;
  };

  @action fetchSalesPagination = async (
    perPage: number,
    page: number,
    order?: string,
    orderBy?: string
  ) => {
    const params = {
      _start: page * perPage,
      _limit: perPage,
      brandKey: this.state,
    } as any;

    if (orderBy) {
      params["_sort"] = `${orderBy}:${order}`;
    }

    const response = await this.api.get("yachts", { params });
    return response.data;
  };

  @action fetchSale = async (id: number) => {
    const response = await this.api.get(`yachts/${id}`);
    this.sales.form = response.data;
    this.sales.files = [];
  };

  @action saveSale = async () => {
    try {
      this.sales.form.brandKey = this.state;

      var formdata = new FormData();
      this.sales.files.map((x: any) => {
        formdata.append("files.attachments", x);
      });

      formdata.append("data", JSON.stringify(this.sales.form));

      const response = await this.api.post("yachts", formdata, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      this.sales.form.id = response.data.id;
      return response;
    } catch (e: any) {
      this.error.has_error = true;
      this.error.message = e.data;
      return null;
    }
  };

  @action updateSale = async () => {
    try {
      var formdata = new FormData();

      this.sales.files.map((x: any) => {
        formdata.append("files.attachments", x);
      });

      formdata.append("data", JSON.stringify(this.sales.form));

      const response = await this.api.put(
        `yachts/${this.sales.form.id}`,
        formdata,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e: any) {
      this.error.has_error = true;
      this.error.message = e.data;
      return null;
    }
  };

  @action deleteSale = async (id?: number) => {
    try {
      const response = await this.api.delete(`yachts/${id}`);
      return response;
    } catch (e: any) {
      this.error.has_error = true;
      this.error.message = e.data;
      return null;
    }
  };

  @action addBroker = () => {
    this.sales.form.brokers.push({});
  };

  @action removeBroker = (i: number) => {
    this.sales.form.brokers.splice(i, 1);
  };

  @action addCost = () => {
    this.sales.form.costs.push({});
  };

  @action removeCost = (i: number) => {
    this.sales.form.costs.splice(i, 1);
  };

  @action addAttachment = () => {
    this.sales.files.push(undefined);
  };

  @action removeAttachment = (i: number) => {
    this.sales.files.splice(i, 1);
  };

  get api() {
    let headers: any = {
      "Access-Control-Allow-Origin": "*",
    };

    if (this.user.token) {
      headers.Authorization = `Bearer ${this.user.token}`;
    }

    var instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers,
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401 || error.response.status == 403) {
          console.log("logout");
          this.logout();
        }

        if (error.response.status === 404) {
          //
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }
}
