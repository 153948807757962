import {
  Button,
  ButtonGroup,
  createStyles,
  Fab,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import React from "react";
import { Layout } from "../../components/Layout";
import { useStores } from "../../contexts";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Visibility";
import { Loading } from "../../components/Loading";
import { Sale } from "../../stores/app.store";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    actionBar: {
      position: "fixed",
      right: 15,
      bottom: 15,
      zIndex: 1000,
    },
    actionBarButton: {
      margin: "0 5px",
    },
  })
);

type Order = "asc" | "desc";

const SortableTableHead = (props: any) => {
  return (
    <TableCell
      sortDirection={props.orderBy === props.name ? props.order : false}
      align={props.align}
    >
      <TableSortLabel
        active={props.orderBy === props.name}
        direction={props.orderBy === props.name ? props.order : "asc"}
        onClick={(e) => props.handleRequestSort(e, props.name)}
      >
        {props.text}
      </TableSortLabel>
    </TableCell>
  );
};

export const SalesListPage = (props: any) => {
  const { appStore } = useStores();
  const classes = useStyles();
  const params = useParams<any>();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState("date");
  const [order, setOrder] = React.useState<Order>("desc");

  React.useEffect(() => {
    appStore.state = params.brand;
    appStore.setMenu(`sales.list${appStore.state ? "." + appStore.state : ""}`);

    console.log(appStore.state);

    setLoading(true);
    fetchData(rowsPerPage, page, order, orderBy);
    setLoading(false);
  }, [params.brand]);

  const fetchData = async (
    perPage: number,
    page: number,
    order?: string,
    orderBy?: string
  ) => {
    const result = await appStore.fetchSalesPagination(
      perPage,
      page,
      order,
      orderBy
    );

    const dataCount = await appStore.fetchSalesCount();

    setData(result);
    setCount(dataCount);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    // fetch data
    fetchData(rowsPerPage, page, direction, property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);

    fetchData(perPage, 0, order, orderBy);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchData(rowsPerPage, newPage, order, orderBy);
  };

  const onNewClicked = () => {
    props.history.push(
      `/sales/${appStore.state ? appStore.state + "/" : ""}new`
    );
  };

  const onViewClicked = (id?: Number) => {
    props.history.push(
      `/sales/${appStore.state ? appStore.state + "/" : ""}edit/${id}`
    );
  };

  if (loading)
    return (
      <Layout history={props.history}>
        <Loading />
      </Layout>
    );

  return (
    <Layout history={props.history}>
      <Grid container spacing={3}>
        <div className={classes.actionBar}>
          <Fab
            color="primary"
            className={classes.actionBarButton}
            onClick={onNewClicked}
          >
            <AddCircleOutlineIcon />
          </Fab>
        </div>

        <Grid item xs={12}>
          <Paper style={{ marginBottom: 60 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <SortableTableHead
                      name="date"
                      text="Close Date"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                    />
                    <SortableTableHead
                      name="brand"
                      text="Brand"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      align="right"
                    />

                    <TableCell align="right">Model</TableCell>

                    <SortableTableHead
                      name="soldPrice"
                      text="Sold Price"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      align="right"
                    />
                    <SortableTableHead
                      name="purchasePrice"
                      text="Purchase Price"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      align="right"
                    />
                    <SortableTableHead
                      name="totalCommissionAmount"
                      text="Commission"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      align="right"
                    />
                    <SortableTableHead
                      name="brokerCostTotal"
                      text="Broker Cost"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      align="right"
                    />
                    <SortableTableHead
                      name="costTotal"
                      text="Costs"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      align="right"
                    />
                    <SortableTableHead
                      name="netPrice"
                      text="Net Profit"
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      align="right"
                    />
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row: Sale) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="right">{row.brand}</TableCell>
                      <TableCell align="right">{row.model}</TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.soldPrice, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.purchasePrice, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(
                          row.totalCommissionAmount,
                          "EUR"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.brokerCostTotal, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.costTotal, "EUR")}
                      </TableCell>
                      <TableCell align="right">
                        {appStore.currencyFormat(row.netPrice, "EUR")}
                      </TableCell>
                      <TableCell align="center">
                        <ButtonGroup size="small" variant="text">
                          <Button onClick={() => onViewClicked(row.id)}>
                            <EditIcon />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};
